exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-landlords-let-properties-index-js": () => import("./../../../src/pages/landlords/let-properties/index.js" /* webpackChunkName: "component---src-pages-landlords-let-properties-index-js" */),
  "component---src-pages-new-developments-index-js": () => import("./../../../src/pages/new-developments/index.js" /* webpackChunkName: "component---src-pages-new-developments-index-js" */),
  "component---src-pages-new-homes-backup-js": () => import("./../../../src/pages/new-homes-backup.js" /* webpackChunkName: "component---src-pages-new-homes-backup-js" */),
  "component---src-pages-new-homes-detail-backup-js": () => import("./../../../src/pages/new-homes-detail-backup.js" /* webpackChunkName: "component---src-pages-new-homes-detail-backup-js" */),
  "component---src-pages-prime-properties-index-js": () => import("./../../../src/pages/prime-properties/index.js" /* webpackChunkName: "component---src-pages-prime-properties-index-js" */),
  "component---src-pages-properties-index-js": () => import("./../../../src/pages/properties/index.js" /* webpackChunkName: "component---src-pages-properties-index-js" */),
  "component---src-pages-property-valuation-thank-you-js": () => import("./../../../src/pages/property-valuation/thank-you.js" /* webpackChunkName: "component---src-pages-property-valuation-thank-you-js" */),
  "component---src-pages-sellers-sold-properties-index-js": () => import("./../../../src/pages/sellers/sold-properties/index.js" /* webpackChunkName: "component---src-pages-sellers-sold-properties-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-track-record-js": () => import("./../../../src/pages/track-record.js" /* webpackChunkName: "component---src-pages-track-record-js" */),
  "component---src-templates-areaguide-details-js": () => import("./../../../src/templates/areaguide-details.js" /* webpackChunkName: "component---src-templates-areaguide-details-js" */),
  "component---src-templates-areaguide-landing-template-js": () => import("./../../../src/templates/areaguide-landing-template.js" /* webpackChunkName: "component---src-templates-areaguide-landing-template-js" */),
  "component---src-templates-banner-with-module-js": () => import("./../../../src/templates/banner-with-module.js" /* webpackChunkName: "component---src-templates-banner-with-module-js" */),
  "component---src-templates-blog-category-listing-template-js": () => import("./../../../src/templates/blog-category-listing-template.js" /* webpackChunkName: "component---src-templates-blog-category-listing-template-js" */),
  "component---src-templates-blog-details-template-js": () => import("./../../../src/templates/blog-details-template.js" /* webpackChunkName: "component---src-templates-blog-details-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-form-template-js": () => import("./../../../src/templates/form-template.js" /* webpackChunkName: "component---src-templates-form-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-kind-words-details-js": () => import("./../../../src/templates/kind-words-details.js" /* webpackChunkName: "component---src-templates-kind-words-details-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-listing-template-js": () => import("./../../../src/templates/listing-template.js" /* webpackChunkName: "component---src-templates-listing-template-js" */),
  "component---src-templates-new-development-details-js": () => import("./../../../src/templates/new-development-details.js" /* webpackChunkName: "component---src-templates-new-development-details-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-static-template-js": () => import("./../../../src/templates/static-template.js" /* webpackChunkName: "component---src-templates-static-template-js" */),
  "component---src-templates-team-details-js": () => import("./../../../src/templates/team-details.js" /* webpackChunkName: "component---src-templates-team-details-js" */),
  "component---src-templates-team-landing-template-js": () => import("./../../../src/templates/team-landing-template.js" /* webpackChunkName: "component---src-templates-team-landing-template-js" */),
  "component---src-templates-valuation-template-js": () => import("./../../../src/templates/valuation-template.js" /* webpackChunkName: "component---src-templates-valuation-template-js" */)
}

